import React from "react"
import styled from "styled-components"
import Anilink from "gatsby-plugin-transition-link/AniLink"

const Konfigurator = props => {
  return (
    <section>
      <p>
        Um unseren VELUX Dachfenster-Konfigurator zu verwenden, geben Sie uns
        bitte per Klick auf das Feld <i>Konfigurator anzeigen</i> Ihr Einverständnis.
        Weitere Informationen finden Sie in unserer{" "}
        <Anilink to="/datenschutz">Datenschutzerklärung</Anilink>.
      </p>
      
      <KonfiguratorWrapper>
      { props.class == "show" &&
      <iframe 
      src="https://dachfensterkonfigurator.velux.de/konfigurator?embed=true&id=10036815" 
      height="1400" 
      width="100%"
      class={props.class}
      title="change-konfigurator"
      aria-label="VELUX Dachfenster-Konfigurator"  
      ></iframe>
      }
      </KonfiguratorWrapper>
      
      <button class={props.classbutton} onClick={props.function}>
        Wir benötigen Ihr Einverständnis. Wir nutzen den VELUX Dachfenster-Konfigurator. Dieser setzt Cookies und sammelt personenbezogene Daten über Ihre
        Aktivitäten.
        <h3>Konfigurator anzeigen</h3>
      </button>
    </section>
  )
}

const KonfiguratorWrapper = styled.div`
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);

  &:hover {
    box-shadow: var(--darkShadow);
  }
`
export default Konfigurator
