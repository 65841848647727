import React, { useState } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import Konfigurator from "../components/Konfigurator/Konfigurator"
import Title from "../components/Title"

const Index = ({ data }) => {
  const [konfigurator, setKonfigurator] = useState("hide")
  const [konfiguratorbutton, setKonfiguratorButton] = useState("show")
  const toggleKonfigurator = function () {
    setKonfigurator("show")
    setKonfiguratorButton("hide")
  }

  return (
    <Layout>
      <SEO title="VELUX Dachfenster-Konfigurator" description={data.site.siteMetadata.description} />
      <ServicesContainer>
        <Title title="VELUX Dachfenster-" subtitle="Konfigurator" />
        <hr className="services_separator" />
        <KonfiguratorWrapper>
          <Konfigurator
            function={toggleKonfigurator}
            class={konfigurator}
            classbutton={konfiguratorbutton}
          ></Konfigurator>
        </KonfiguratorWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const KonfiguratorWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  padding: 2rem;
`
export default Index
